.custyle {
    padding-left: 5px;
    padding-right: 5px;
}
.custab {
    border: 1px solid #ccc;
    /* padding: 5px; */
    /* width: 100%; */
    /* margin: 5% 0; */
    box-shadow: 3px 3px 2px #ccc;
    transition: 0.5s;
}
.custab:hover {
    box-shadow: 3px 3px 0px transparent;
    transition: 0.5s;
}
.custab th {
    text-align: center;
}
.custab td {
    text-align: center;
}
.table-result th {
    text-align: right;
    padding-right: 2%;
    width: 50%;
}
.table-result td {
    text-align: left;
    padding-left: 2%;
}

.result-primary {
    color: blue;
    font-weight: bold;
}

.result-wrong {
    color: red;
    font-weight: bold;
}

/* .floating-modal {
    position: absolute;
    width: 185px;
    height: 35px;
    right: 0px;
    top: 0px;
    z-index: 100;
} */

.bottom-border { border-bottom: 1px dashed gray; }

.table-result-2 th {
    text-align: left;
    padding-left: 2%;
    width: 50%;
}

.table-result-2 td {
    text-align: left;
    padding-left: 2%;
}

.table-text thead {
    font-size: 14px;
}

.table-text td {
    font-size: 14px;
}

