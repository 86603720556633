.ol-alpha {
    counter-reset: list;

    // li {
    //     list-style: none;
    // }
    
    li:before {
        content: "(" counter(list, lower-alpha) ") ";
        counter-increment: list;
        margin-left: -33px; 
        margin-right: 10px;
    }
}

.ul-disc {
    list-style-type: disc;
}

.ul-disc-inner {

    li {
        list-style-type: disc;
    }
    
    li:before {
        content: '';
        margin-left: 0px; 
        margin-right: 0px;
    }
}

.ul-circle {
    list-style-type: circle;
}

.ul-circle-inner {

    li {
        list-style-type: circle;
    }
    
    li:before {
        content: '';
        margin-left: 0px; 
        margin-right: 0px;
    }
}