/* 
tr .spnTooltip {
    z-index:10;
    display:none; 
    padding:14px 20px;
    margin-top: -50px; 
    margin-left:28px;
    width:auto; 
    line-height:16px;
    border-radius: 15px;
}

tr:hover .spnTooltip {
    display:inline; 
    position:absolute; 
    color:#111;
    border:1px solid #DCA; 
    background:#fffAF0;
}

.callout {
    z-index:20;
    position:absolute;
    top:30px;
    border:0;
    left:-12px;
} */

.pointer {
    cursor: pointer;
}

.highlight {
    /* border: 2px;
    border-color: red;
    border-style: solid;
    border-radius: 5px;
    padding: 5px; */
    /* border-width: 5px !important; */
    border-width: 2px !important;
    border-color: red !important;
}

.select-highlight {

    /* border: 5px; */

    /* border-color: #ced4da;
    padding: 5px; */

    border-color: red;
    padding: 0%;

    border-radius: 5px;
    border-style: solid;

    border-width: 2px !important;
}


