/*** 2021.04.08 - custom right-click popup menu *** start ***/
.contextMenu {
  position: fixed;
  background: white;
  box-shadow: 0px 2px 10px #999999;

  option {
    padding: 6px 50px 5px 10px;
    min-width: 160px;
    cursor: default;
    font-size: 12px;

    :hover {
      background: linear-gradient(to top, #555, #333);
      color: white;
    }

    :active {
      color: #e9e9e9;
      background: linear-gradient(to top, #555, #444);
    }

    :disabled {
      color: #999999;
      pointer-events: none;
    }
  }

  separator {
    width: 100%;
    height: 1px;
    background: #cccccc;
    margin: 0 0 0 0;
  }
}
/*** 2021.04.08 - custom right-click popup menu *** end ***/

.card-sponsor-mini {
  .card {
    .card-body {
      padding: 0.75rem;
    }
  }
}

.alert-dialog-bordered {
  .modal-content {
    border: 4px solid darkseagreen;
  }
}

.form-warning-text {
  width: 100%;
  text-align: center;
  font-style: italic;
  font-weight: normal;
  padding: 5%;
}

.stuQuizRooms {
  tbody > tr > td {
    vertical-align: middle;
  }
}

.event-custom {
  gap: 15px;
  .event-card {
    width: 100%;
    background-color: lavender;
    cursor: pointer;
    .card-title {
      margin: 0px;
      font-size: medium;
    }
    .card-body {
      background-color: #fff;
      color: #000;
      padding: 10px;
      .card-table {
        width: 100%;
        .td-register {
          width: 155px;
          .btn {
            width: 150px;
            height: 75px;
          }
          .btn:hover {
            background-color: rgba(13, 110, 253, 0.75);
          }
        }
        .sub-date {
          color: gray;
        }
      }
      .card-table > tr > th,
      td {
        padding: 0px 5px 0px 5px;
      }
    }
  }
  .event-card:hover {
    background-color: rgba(13, 110, 253, 0.75);
    color: #fff;
  }
}
