.privew {
    margin-bottom: 20px;
}

.questionsBox {
    display: block;
    border: solid 1px #e3e3e3;
    padding: 10px 20px 0px;
    box-shadow: inset 0 0 30px rgba(000,000,000,0.1), inset 0 0 4px rgba(255,255,255,1);
    border-radius: 3px;
    /* margin: 0 10px; */
}
.questions {
    /* background: #007fbe; */
    background: #007bff;
    color: #FFF;
    font-size: 18px;
    padding: 8px 30px;
    font-weight: 300;
    margin: 0 -30px 10px;
    position: relative;
}
.questions:after {
    /* background: url(../img/icon.png) no-repeat left 0; */
    display: block;
    position: absolute;
    top: 100%;
    width: 9px;
    height: 7px;
    content: '.';
    left: 0;
    text-align: left;
    font-size: 0;
}
.questions:after {
    left: auto;
    right: 0;
    background-position: -10px 0;
}
.questions:before, .questions:after {
    background: black;
    display: block;
    position: absolute;
    top: 100%;
    width: 9px;
    height: 7px;
    content: '.';
    left: 0;
    text-align: left;
    font-size: 0;
}
.answerList {
    /* margin-bottom: 15px; */
    margin: 0px -10px 15px -10px;
}


ol, ul {
    list-style: none;
}
.answerList li:first-child {
    border-top-width: 0;
}

.answerList li {
    padding: 3px 0;
}
.answerList label {
    display: block;
    /* max-width: auto; */
    padding: 6px;
    border-radius: 6px;
    border: solid 1px #dde7e8;
    font-weight: 400;
    font-size: 14px;
    cursor: pointer;
    font-family: Arial, sans-serif;
    margin-left: -40px;
}
.answerList-inactive label {
    display: block;
    /* max-width: auto; */
    padding: 6px;
    border-radius: 6px;
    border: solid 1px #dde7e8;
    font-weight: 400;
    font-size: 14px;
    /* cursor: pointer; */
    font-family: Arial, sans-serif;
    margin-left: -40px;
}
.answerList-1 label {
    display: block;
    /* max-width: auto; */
    padding: 6px;
    border-radius: 6px;
    border: solid 1px #dde7e8;
    font-weight: 400;
    font-size: 14px;
    /* cursor: pointer; */
    font-family: Arial, sans-serif;
    margin-left: -40px;
}
.ans-col-1 {
    padding-top: 4px;
    /* border: solid 1px black; */
    /* width: 10%; */
}
.ans-col-2 {
    padding-top: 3px;
}
.ans-col-2-cols {
    vertical-align: text-top;
    padding-top: 4px;
    padding-left: 10px;
    /* padding: 0; */
    /* border: solid 1px black; */
}


input[type=checkbox], input[type=radio] {
    margin: 4px 0 0;
    margin-top: 1px;
    line-height: normal;
}
.questionsRow {
    background: #dee3e6;
    margin: 0 -20px;
    padding: 10px 10px;
    border-radius: 0 0 3px 3px;
    align-self: top;
}
.button, .greyButton {
    background-color: #f2f2f2;
    color: #888888;
    display: inline-block;
    border: solid 3px #cccccc;
    vertical-align: middle;
    text-shadow: 0 1px 0 #ffffff;
    line-height: 27px;
    min-width: 160px;
    text-align: center;
    padding: 5px 20px;
    text-decoration: none;
    border-radius: 0px;
    text-transform: capitalize;
}
.questionsRow span {
    /* float: left; */
    display: inline-block;
    line-height: 30px;
    border: solid 1px #aeb9c0;
    padding: 0px 10px;
    background: #FFF;
    color: #007fbe;
    /* text-align: center; */
}

.questionsRow button {
    float: right;
}

.textBold {
    font-weight: bold;
}

.questionPicture {
    padding-bottom: 10px;
}

.btnAnswerOption {
    width: 40%;
    /* height: auto; */
    min-height: 50px;
    margin: 10px;
    flex: 1;
}

.answerOptionDiv {
    /* width: auto; */
    height: auto;
    padding: 0px 0px 0px 0px;   /* top, right, bottom, left  */
    justify-content: center;
    align-items: center;
    text-align: center;
}

.qs-Tb {
    /* color: black; */
    /* background-color: white; */
    color: white;
    background-color: #007bff;
    border: 1px white solid;
}

.qs-Tb td {
    text-align: center;
    max-width: 240px;
    border: 1px white solid;
}

.qs-Tb .btn-outline-light {
    border: 2px white solid;
}

/*** custom qs btn style - 2021.04.08 - start ***/
.btnAns {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    user-select: none;
    /* background-color: transparent; */
    height: 40px;
    border: 1px solid white;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.btnAns:disabled {
    opacity: 0.65;
}
.btn-answer, .btn-answer:disabled, .btn-answer:disabled:hover {
    background-color: white;
    color: black;
}
.btn-answer:hover, .btn-answer:active {
    background-color: transparent;
    color: white;
}
.btn-outline-answer, .btn-outline-answer:disabled, .btn-outline-answer:disabled:hover {
    background-color: transparent;
    color: white;
}
.btn-outline-answer:hover, .btn-outline-answer:active {
    background-color: white;
    color: black;
}
/*** custom qs btn style - 2021.04.08 - end ***/